import React from "react"
import PropTypes from 'prop-types'
import Layout from "../components/layout"
import { withTranslation } from 'react-i18next'
import Header from "../sections/Header/Header"
import ClientReviews from "../sections/ClientReviews/ClientReviews"
import GetInTouch from '../sections/GetInTouch/GetInTouch'
import headerImg from '../assets/Salona_Consulting_Dubrovnik_Old_Town-min.jpg'
import WhereToFindUs from "../sections/WhereToFindUs/WhereToFindUs"
import SEO from "../components/seo"
import '../styles/main.scss'
import i18n from '../i18n'

const IndexPage = ({t}) => {
  if (i18n.language !== 'es') {
    i18n.changeLanguage('es')
  }
  return (
    <Layout id='contact'>
      <SEO title={t("TITLE_CONTACT")} metaT={t("META_CONTACT")} />
      <Header 
        image={headerImg}
        title={t('CONTACT_TITLE')}
        subtitle={t('CONTACT_SUBTITLE')}
        actionTitle={t('CONTACT_START_JURNEY')}
        btnClass='btn-2'
        section={"get-in-touch"}
      />
      <GetInTouch />
      <WhereToFindUs />
      <ClientReviews />
    </Layout>
  )
}

IndexPage.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(IndexPage)
